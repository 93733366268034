
p{
  padding-left: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
}

h3{
  padding-left: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 20px;
}

h1
{
  color: #424242;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

h2
{
  color: #646464;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-left: 5px;
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
}

body
{
  background-color: #e6e6e6;
}

.app-header
{
  background-color: rgb(255, 255, 255);
  width: 100%;
}

.app-header h1
{
  color: rgb(31, 31, 31);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding-left: 5px;
  font-size: 30px;
}

.tmk-logo
{
  margin: auto;
  width: 50px;
  padding: 10px;
}

.mapContainer
{
  height: 850px;
}

.widget {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  font-family: 'Roboto', sans-serif;

  padding: 30px;
  width: 70%;
  margin: auto;
}

.widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
.widget li {
  border-bottom: 1px solid gray;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.widget li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.widget a {
  text-decoration: none;
  color:  black;
  display: inline-block;
}
.widget li:before {
  font-family: FontAwesome;
  font-size: 20px;
  vertical-align:bottom;
  color: #dd3333; 
  margin-right: 14px;
}

.background-image
{
  background-image: url('../public/Icons/background.jpg');
  background-size: cover;
  min-height: 100vh;
  background-position: center center;
}

.phone-widget {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  font-family: 'Roboto', sans-serif;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.phone-widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
}
.phone-widget li {
  border-bottom: 1px solid rgba(128, 128, 128, 0.436);
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.phone-widget li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.phone-widget a {
  text-decoration: none;
  color:  black;
  display: inline-block;
}
.phone-widget li:before {
  font-family: FontAwesome;
  font-size: 20px;
  vertical-align:bottom;
  color: #dd3333; 
  margin-right: 14px;
}
.busses{
  padding: 10px;
  padding-top: 0;
  padding-bottom: 70px;
}

.busses a{
  text-decoration: none;
  color: black;
}

.test
{
  padding-top: 10px;
  font-family: 'Roboto', sans-serif;
}

.social-media
{
  margin: auto;
  width: 320px;
  padding-top: 20px;
}

.fa {
  padding: 20px;
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  background-color: #bfbfbfe0;
  color: white;
  margin: 5px;
}

.fa-ul li
{
  padding: 3px;
  font-size: 17px;
}

.fa-solid{
  font-family: FontAwesome;
  color: #db7107;
}

.fa-regular{
  font-family: FontAwesome;
  color: #db7107;
}

.tmk-header
{
  border-bottom: solid rgb(141, 141, 141) 2px;
  width: 100%;
}

.tmk-header p
{
  font-size: 20px;
  color: #db7107;
  line-height: 0px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.tmk-text
{
  display: inline-block;
}

.tmk-header img
{
  max-width: 42px;
  display: inline-block;
  right: 10px;
  top: 3px;
  position: absolute;
}

.job-list
{
  padding-top: 20px;
}

.nav-back
{
  display: inline-block;
}

.nav-back h2
{
  color: #db7107;
  display: inline-block;
  font-weight: bold;
}